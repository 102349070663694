import Head from 'next/head'
import Image from 'next/image'
import Link from 'next/link'
import Script from 'next/script'
import { GetStaticProps } from 'next'
import getArticleTitle from '../lib/getArticles'
import getHomeHeroImageLink from '../lib/getHomeHeroImageLink'
import getHomeArticles from '../lib/getHomeArticles'
import md from 'markdown-it';

declare global {
  interface Window {
    netlifyIdentity: any;
  }
}

export const getStaticProps: GetStaticProps = async (context) => {

  const imageData = getHomeHeroImageLink()
  const homeArticles = getHomeArticles()

  return {
    props: {
      homeArticles,
      imageData
    }
  }
}

export default function Home({ homeArticles, imageData }: any) {
  const articles = homeArticles.homeArticles
  const imageLink = imageData.data.image
  const imageHeight = imageData.data.height
  const imageWidth = imageData.data.width

  const miniCards = [
    {
      title: "The Omnibus Map",
      desc: "This visual guide shows you all the potential ways to move between omnibus and omnibus.",
      link: "/reading-order",
      "button-text": "Open the visual Guide",
      imageSrc: "/img/site-nav-map.png",
      height: 175,
      width: 175
    },
    {
      title: "FAQ",
      desc: "What's an omnibus? What's the Horus Heresy? And how is this website supposed to help? We answers this and more in this section.",
      link: "/faq",
      "button-text": "Open the FAQ",
      imageSrc: "/img/site-nav-faq.png",
      height: 175,
      width: 175
    },
    {
      title: "Additional References",
      desc: "Here you can find sources for videos, reviews and wikis that engage with Warhammer 40,000 and the Horus Heresy",
      link: "/additional-references",
      "button-text": "Open the References",
      imageSrc: "/img/site-nav-resources.png",
      height: 175,
      width: 230
    }
  ]



  return (
    <div className=''>
      <Head>
          <title>Horus Heresy Omnibus Project - Home</title>
          <meta name="description" content="A Fan-Created Guide for Navigating the Epic Series" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
      <main className="">
        <section className='bg-black'>
          <div className='flex flex-col justify-between items-center max-w-6xl sm:flex-row mx-auto'>
            <div className='sm:w-3/5 p-8'>
              <span className="text-sm text-heresy-primary">The unofficial</span>
              <h1 className="text-3xl leading-tight mt-2 mb-4 text-heresy-primary">Horus Heresy Omnibus Project</h1>
              <p className='mb-2 text-lg'>The Horus Heresy book series is truly epic, but its massive number of stories can be overwhelming to traverse.</p>
              <p className='mb-6'>To help with navigating the winding paths of the series, we have arranged every novel, novella and short story of the Horus Heresy into a comprehensive reading order of twenty-one story packages called <b>omnibuses</b>. </p>
              <button className='px-3 py-2 bg-heresy-bg-dark rounded-md font-medium hover:bg-heresy-bg-light hover:text-white'>
                <Link href="/omnibus/i-heresy-rising">Start with the first Omnibus</Link></button>
            </div>
            <div className='sm:w-2/5 p-8 bg-center bg-cover bg-no-repeat'>
              <Image src={imageLink} height={imageHeight} width={imageWidth} alt="Hero Image" />
            </div>
          </div>
        </section>
        <section className='bg-heresy-bg-default'>
          <div className='flex justify-evenly mx-auto p-8 flex-col max-w-6xl sm:flex-row items-center'>
            {miniCards.map((card, index) => (
              <Link href={card.link} key={"card-" + index} passHref>
                <a>
                  <div key={'card-' + index} className='bg-heresy-bg-light shadow-xl min-h-[25rem] p-8 max-w-xs flex flex-col justify-center rounded-md mb-4 sm:mx-4'>
                    <div>
                      <h3 className='text-xl leading-tight text-heresy-primary text-center mb-2'>{card.title}</h3>
                      <p className='text-center'>{card.desc}</p>
                    </div>
                    <div className='mx-auto'>
                      <Image src={card.imageSrc} height={card.height} width={card.width} layout='fixed' alt={card.title + " image"} />
                    </div>
                  </div>
                </a>

              </Link>

            ))}
          </div>
        </section>
        <section className='bg-heresy-bg-default'>
          <div className='max-w-6xl mx-auto p-8 text-center'>
            <h1 className="text-3xl leading-tight mt-2 mb-4 text-heresy-primary">Other Articles</h1>
            <section className="flex flex-row justify-center flex-wrap items-center content-center">
              {articles.map((article: any, index: any) => (
                <div className='mb-4 sm:m-4 flex-2 min-w-[18rem]' key={'article-' + index}>
                  <Link href={`/article/${article.path}`}>
                    <a>
                      <div className="m-[1px] hover:m-0 rounded-sm hover:border-2 border border-heresy-primary text-left flex flex-col">
                        <div className=''>
                          {article.cardImage && <Image src={article.cardImage} alt={article.title + " image"} height={300} width={500} layout='responsive' />}
                          <span className=''></span>
                        </div>
                        <div className='p-6 min-h-[10rem]'>
                          <span>{article.formatedDate}</span>
                          <h3 className="text-xl font-bold">{article.title}</h3>
                        </div>
                      </div>
                    </a>
                  </Link>
                </div>
              ))}
            </section>
          </div>
        </section>
      </main>
    </div>
  )
}
